$(document).foundation();

// $(function() {
//     $(window).scroll(function() {
//       var winTop = $(window).scrollTop();
//       if (winTop >= 50) {
// //        $("body").addClass("sticky-shrinknav-wrapper");
//         $(".navLogo").show(400);
//       } else {
// //        $("body").removeClass("sticky-shrinknav-wrapper");
//         $(".navLogo").hide(500);
//       }
//     });
//   });
  
  